import { Client } from 'nice-grpc-web';
import {
  AddFieldsRequest,
  DeepPartial, DeleteFieldsRequest,
  FieldServiceDefinition,
  ListFields_Request,
  ListFields_Response,
  ListFieldsForAsset_Request,
  ListFieldsForAsset_Response,
  ListFieldsForModem_Request, UpdateFieldRequest
} from '../customer_compiled/field_service';
import { GrpcClientFactoryType } from './types';
import { ModemMessageBodyParser } from "../customer_compiled/modem_message_body_parser";
import { Field } from "../customer_compiled/field";

export default class FieldService {
  private static instance: FieldService;
  private readonly client: Client<typeof FieldServiceDefinition>;

  private constructor(grpcClientFactory: GrpcClientFactoryType) {
    this.client = grpcClientFactory<typeof FieldServiceDefinition>(
      FieldServiceDefinition,
    );
  }

  static getInstance(grpcClientFactory: GrpcClientFactoryType) {
    if (!FieldService.instance) {
      FieldService.instance = new FieldService(grpcClientFactory);
    }

    return FieldService.instance;
  }

  add(payload: DeepPartial<AddFieldsRequest>): Promise<ModemMessageBodyParser> {
    const request = AddFieldsRequest.fromPartial(payload);
    return this.client.add(request);
  }

  update(payload: DeepPartial<UpdateFieldRequest>): Promise<Field> {
    const request = UpdateFieldRequest.fromPartial(payload);
    return this.client.update(request);
  }

  delete(payload: DeepPartial<DeleteFieldsRequest>): Promise<ModemMessageBodyParser> {
    const request = DeleteFieldsRequest.fromPartial(payload);
    return this.client.delete(request);
  }

  listFields(payload: DeepPartial<ListFields_Request>): Promise<ListFields_Response> {
    const request = ListFields_Request.fromPartial(payload);
    return this.client.list(request);
  }

  listFieldsForModems(payload: DeepPartial<ListFieldsForModem_Request>) {
    const request = ListFieldsForModem_Request.fromPartial(payload);
    return this.client.forModem(request);
  }

  listFieldsForAsset(payload: DeepPartial<ListFieldsForAsset_Request>): Promise<ListFieldsForAsset_Response> {
    const request = ListFieldsForAsset_Request.fromPartial(payload);
    return this.client.forAsset(request);
  }
}
