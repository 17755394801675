// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: field_service.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { AssetSelection } from "./asset";
import {
  Pagination,
  Pagination_Result,
  UnitOfMeasurement,
  unitOfMeasurementFromJSON,
  unitOfMeasurementToNumber,
  UpdateBoolean,
  UpdateClearableString,
  UpdateZeroableInt,
} from "./base";
import { Field, Field_Numeric_Format, Field_Numeric_Unit } from "./field";
import { ModemSelection } from "./modem";
import { ModemMessageBodyParser } from "./modem_message_body_parser";
import {
  Value_Enum,
  Value_Numeric_Type,
  value_Numeric_TypeFromJSON,
  value_Numeric_TypeToNumber,
  Value_Type,
  value_TypeFromJSON,
  value_TypeToNumber,
} from "./value";

export const protobufPackage = "hiber.field";

export interface FieldSelection {
  /** Search the fields (matches field, display_name and parser identifier). */
  search?:
    | string
    | undefined;
  /** Only return the fields owned by your organization (exclude fields from inherited parsers). */
  onlyOwned?:
    | boolean
    | undefined;
  /** Filter fields by type. */
  types: Value_Type[];
  /** Filter fields by numeric type. Limits field types to numeric. */
  numericTypes: Value_Numeric_Type[];
  /** Filter fields by field identifiers. */
  fieldIdentifiers: string[];
  /** Include operational fields as well (e.g. battery, device status). */
  includeOperational?: boolean | undefined;
}

export interface ListFields {
}

export enum ListFields_Sort {
  /** PARSER_PRIORITY - Sort by parser and priority in the parser. */
  PARSER_PRIORITY = "PARSER_PRIORITY",
  /** DISPLAY_NAME - Sort by display_name. */
  DISPLAY_NAME = "DISPLAY_NAME",
  /** DISPLAY_NAME_DESC - Sort by display_name. */
  DISPLAY_NAME_DESC = "DISPLAY_NAME_DESC",
  /** FIELD_PATH - Sort by json path only. */
  FIELD_PATH = "FIELD_PATH",
  /** FIELD_PATH_DESC - Sort by json path only. */
  FIELD_PATH_DESC = "FIELD_PATH_DESC",
  /** TYPE - Sort by type and numeric value type (if numeric). */
  TYPE = "TYPE",
  /** TYPE_DESC - Sort by type and numeric value type (if numeric). */
  TYPE_DESC = "TYPE_DESC",
  /** UNIT - Sort by numeric value unit. */
  UNIT = "UNIT",
  /** UNIT_DESC - Sort by numeric value unit. */
  UNIT_DESC = "UNIT_DESC",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function listFields_SortFromJSON(object: any): ListFields_Sort {
  switch (object) {
    case 0:
    case "PARSER_PRIORITY":
      return ListFields_Sort.PARSER_PRIORITY;
    case 1:
    case "DISPLAY_NAME":
      return ListFields_Sort.DISPLAY_NAME;
    case 5:
    case "DISPLAY_NAME_DESC":
      return ListFields_Sort.DISPLAY_NAME_DESC;
    case 2:
    case "FIELD_PATH":
      return ListFields_Sort.FIELD_PATH;
    case 6:
    case "FIELD_PATH_DESC":
      return ListFields_Sort.FIELD_PATH_DESC;
    case 3:
    case "TYPE":
      return ListFields_Sort.TYPE;
    case 7:
    case "TYPE_DESC":
      return ListFields_Sort.TYPE_DESC;
    case 4:
    case "UNIT":
      return ListFields_Sort.UNIT;
    case 8:
    case "UNIT_DESC":
      return ListFields_Sort.UNIT_DESC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ListFields_Sort.UNRECOGNIZED;
  }
}

export function listFields_SortToNumber(object: ListFields_Sort): number {
  switch (object) {
    case ListFields_Sort.PARSER_PRIORITY:
      return 0;
    case ListFields_Sort.DISPLAY_NAME:
      return 1;
    case ListFields_Sort.DISPLAY_NAME_DESC:
      return 5;
    case ListFields_Sort.FIELD_PATH:
      return 2;
    case ListFields_Sort.FIELD_PATH_DESC:
      return 6;
    case ListFields_Sort.TYPE:
      return 3;
    case ListFields_Sort.TYPE_DESC:
      return 7;
    case ListFields_Sort.UNIT:
      return 4;
    case ListFields_Sort.UNIT_DESC:
      return 8;
    case ListFields_Sort.UNRECOGNIZED:
    default:
      return -1;
  }
}

export interface ListFields_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select which fields to return. Optional, when omitted or empty everything is included. */
  selection?: FieldSelection | undefined;
  pagination?: Pagination | undefined;
  sort?:
    | ListFields_Sort
    | undefined;
  /**
   * Whether to apply the unit preferences to the fields.
   * This will convert any fields into your preferred unit, for convenience.
   */
  applyUnitPreferences?: boolean | undefined;
}

export interface ListFields_Response {
  fields: Field[];
  request: ListFields_Request | undefined;
  pagination: Pagination_Result | undefined;
}

export interface ListFieldsForAsset {
}

export interface ListFieldsForAsset_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the assets to fetch the fields for. Optional, when omitted or empty everything is included. */
  assetSelection?:
    | AssetSelection
    | undefined;
  /** Select which fields to return. Optional, when omitted or empty everything is included. */
  fieldSelection?: FieldSelection | undefined;
  pagination?: Pagination | undefined;
  sort: ListFields_Sort;
  /**
   * Whether to apply the unit preferences to the fields.
   * This will convert any fields into you preferred unit, for convenience.
   */
  applyUnitPreferences?:
    | boolean
    | undefined;
  /**
   * Whether to also calculate the total fields for all selected assets,
   * and return them as a single list in the response.
   * This can be useful to display table columns, for example.
   */
  includeTotal?:
    | boolean
    | undefined;
  /** Whether to include fields that were only present from previously assigned devices. */
  includeHistoricFields?: boolean | undefined;
}

export interface ListFieldsForAsset_Response {
  assetFields: ListFieldsForAsset_Response_AssetWithFields[];
  request: ListFieldsForAsset_Request | undefined;
  pagination:
    | Pagination_Result
    | undefined;
  /** A merged result of all fields, for all assets. This can be useful to display table columns, for example. */
  total: Field[];
}

export interface ListFieldsForAsset_Response_AssetWithFields {
  assetName: string;
  assetIdentifier: string;
  fields: Field[];
}

export interface ListFieldsForModem {
}

export interface ListFieldsForModem_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** Select the modems to fetch the fields for. Optional, when omitted or empty everything is included. */
  modemSelection?:
    | ModemSelection
    | undefined;
  /** Select which fields to return. Optional, when omitted or empty everything is included. */
  fieldSelection?: FieldSelection | undefined;
  pagination?: Pagination | undefined;
  sort: ListFields_Sort;
  /**
   * Whether to apply the unit preferences to the fields.
   * This will convert any fields into you preferred unit, for convenience.
   */
  applyUnitPreferences?:
    | boolean
    | undefined;
  /**
   * Whether to also calculate the total fields for all selected modems,
   * and return them as a single list in the response.
   * This can be useful to display table columns, for example.
   */
  includeTotal?: boolean | undefined;
}

export interface ListFieldsForModem_Response {
  modemFields: ListFieldsForModem_Response_ModemWithFields[];
  request: ListFieldsForModem_Request | undefined;
  pagination:
    | Pagination_Result
    | undefined;
  /** A merged result of all fields, for all modems. This can be useful to display table columns, for example. */
  total: Field[];
}

export interface ListFieldsForModem_Response_ModemWithFields {
  modem: string;
  name: string;
  identifier: string;
  fields: Field[];
}

export interface AddFieldsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier for the parser to remove the field from. */
  parserIdentifier: string;
  /** The fields to add to the parser. */
  fields: Field[];
}

/** Replace all fields for a parser with the fields given. */
export interface ReplaceAllFieldsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier for the parser to remove the field from. */
  parserIdentifier: string;
  /** The fields to add to the parser, replacing all previous fields. */
  replaceWithFields: Field[];
}

/** Delete the given fields from a parser. */
export interface DeleteFieldsRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier for the parser to remove the field from. */
  parserIdentifier: string;
  /** The name or JsonPath of the field to remove. */
  fieldIdentifiers: string[];
}

export interface UpdateFieldRequest {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier for the field. */
  fieldIdentifier: string;
  /** An optional display name for the field. */
  displayName?:
    | string
    | undefined;
  /** @deprecated */
  deprecatedDisplayName?:
    | UpdateClearableString
    | undefined;
  /** Priority of the field, typically used for ordering. */
  priority?:
    | number
    | undefined;
  /** @deprecated */
  deprecatedPriority?:
    | UpdateZeroableInt
    | undefined;
  /**
   * Whether this field should be stored encrypted or not.
   * When set to true at a later point, earlier values are not encrypted retro-actively.
   */
  encrypted?:
    | boolean
    | undefined;
  /** @deprecated */
  deprecatedEncrypted?:
    | UpdateBoolean
    | undefined;
  /** Whether this field is optional or not. */
  optional?:
    | boolean
    | undefined;
  /** @deprecated */
  deprecatedOptional?:
    | UpdateBoolean
    | undefined;
  /** If the field is used by operations and does not produce process values. */
  operational?: boolean | undefined;
  updateDetails?: { $case: "numeric"; numeric: UpdateFieldNumericDetails } | {
    $case: "enum";
    enum: UpdateFieldEnumValues;
  } | undefined;
}

export interface UpdateFieldNumericDetails {
  /** Replace the unit for this field. Automatically replaces the type and the symbol based on the unit. */
  replaceUnit?:
    | Field_Numeric_Unit
    | undefined;
  /** Replace the formatting for this field. The entire object must be configured. */
  replaceFormat?:
    | Field_Numeric_Format
    | undefined;
  /**
   * Replace the unit for this field. Automatically replaces the type and the symbol based on the unit.
   * Deprecated: use replace_unit instead
   *
   * @deprecated
   */
  replaceUnitOfMeasurement?: UnitOfMeasurement | undefined;
}

export interface UpdateFieldNumericDetails_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier for the field. */
  fieldIdentifier: string;
  update: UpdateFieldNumericDetails | undefined;
}

export interface UpdateFieldEnumValues {
  /** Add new enum values to this field. Fails when any already exist. */
  addValues: Value_Enum[];
  /** Remove enum values from this field. */
  removeValues: string[];
  /** Update enum value details for this field. */
  updateValues: UpdateFieldEnumValues_UpdateEnumValue[];
  /**
   * Delete all enum values for this field and replace them with this set of enum values.
   * Cannot be combined with update_values, for obvious reasons.
   */
  replaceAllValues: Value_Enum[];
}

export interface UpdateFieldEnumValues_Request {
  /** Pick the organization to use (/impersonate). If unset, your default organization is used. */
  organization?:
    | string
    | undefined;
  /** The identifier for the field. */
  fieldIdentifier: string;
  update: UpdateFieldEnumValues | undefined;
}

export interface UpdateFieldEnumValues_UpdateEnumValue {
  /** The enum value. This might be a cryptic value, see the display_name and description for more information. */
  value: string;
  /** Update the user-facing name for this value. */
  displayName?:
    | string
    | undefined;
  /** @deprecated */
  updateDisplayName:
    | UpdateClearableString
    | undefined;
  /** Update the description for this enum value. */
  description?:
    | string
    | undefined;
  /** @deprecated */
  updateDescription:
    | UpdateClearableString
    | undefined;
  /** Update the color for this enum value. */
  color?:
    | string
    | undefined;
  /** @deprecated */
  updateColor:
    | UpdateClearableString
    | undefined;
  /** Update the priority, which is used to sort the enum values. */
  priority?:
    | number
    | undefined;
  /** @deprecated */
  updatePriority: UpdateZeroableInt | undefined;
}

function createBaseFieldSelection(): FieldSelection {
  return {
    search: undefined,
    onlyOwned: undefined,
    types: [],
    numericTypes: [],
    fieldIdentifiers: [],
    includeOperational: undefined,
  };
}

export const FieldSelection = {
  encode(message: FieldSelection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.search !== undefined) {
      writer.uint32(10).string(message.search);
    }
    if (message.onlyOwned !== undefined) {
      writer.uint32(16).bool(message.onlyOwned);
    }
    writer.uint32(26).fork();
    for (const v of message.types) {
      writer.int32(value_TypeToNumber(v));
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.numericTypes) {
      writer.int32(value_Numeric_TypeToNumber(v));
    }
    writer.ldelim();
    for (const v of message.fieldIdentifiers) {
      writer.uint32(42).string(v!);
    }
    if (message.includeOperational !== undefined) {
      writer.uint32(48).bool(message.includeOperational);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FieldSelection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFieldSelection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.search = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.onlyOwned = reader.bool();
          continue;
        case 3:
          if (tag === 24) {
            message.types.push(value_TypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.types.push(value_TypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 4:
          if (tag === 32) {
            message.numericTypes.push(value_Numeric_TypeFromJSON(reader.int32()));

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.numericTypes.push(value_Numeric_TypeFromJSON(reader.int32()));
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fieldIdentifiers.push(reader.string());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.includeOperational = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<FieldSelection>): FieldSelection {
    return FieldSelection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<FieldSelection>): FieldSelection {
    const message = createBaseFieldSelection();
    message.search = object.search ?? undefined;
    message.onlyOwned = object.onlyOwned ?? undefined;
    message.types = object.types?.map((e) => e) || [];
    message.numericTypes = object.numericTypes?.map((e) => e) || [];
    message.fieldIdentifiers = object.fieldIdentifiers?.map((e) => e) || [];
    message.includeOperational = object.includeOperational ?? undefined;
    return message;
  },
};

function createBaseListFields(): ListFields {
  return {};
}

export const ListFields = {
  encode(_: ListFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFields>): ListFields {
    return ListFields.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListFields>): ListFields {
    const message = createBaseListFields();
    return message;
  },
};

function createBaseListFields_Request(): ListFields_Request {
  return {
    organization: undefined,
    selection: undefined,
    pagination: undefined,
    sort: undefined,
    applyUnitPreferences: undefined,
  };
}

export const ListFields_Request = {
  encode(message: ListFields_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.selection !== undefined) {
      FieldSelection.encode(message.selection, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    if (message.sort !== undefined) {
      writer.uint32(32).int32(listFields_SortToNumber(message.sort));
    }
    if (message.applyUnitPreferences !== undefined) {
      writer.uint32(40).bool(message.applyUnitPreferences);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFields_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFields_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.selection = FieldSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sort = listFields_SortFromJSON(reader.int32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.applyUnitPreferences = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFields_Request>): ListFields_Request {
    return ListFields_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListFields_Request>): ListFields_Request {
    const message = createBaseListFields_Request();
    message.organization = object.organization ?? undefined;
    message.selection = (object.selection !== undefined && object.selection !== null)
      ? FieldSelection.fromPartial(object.selection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sort = object.sort ?? undefined;
    message.applyUnitPreferences = object.applyUnitPreferences ?? undefined;
    return message;
  },
};

function createBaseListFields_Response(): ListFields_Response {
  return { fields: [], request: undefined, pagination: undefined };
}

export const ListFields_Response = {
  encode(message: ListFields_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fields) {
      Field.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListFields_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFields_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFields_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fields.push(Field.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListFields_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFields_Response>): ListFields_Response {
    return ListFields_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListFields_Response>): ListFields_Response {
    const message = createBaseListFields_Response();
    message.fields = object.fields?.map((e) => Field.fromPartial(e)) || [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListFields_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseListFieldsForAsset(): ListFieldsForAsset {
  return {};
}

export const ListFieldsForAsset = {
  encode(_: ListFieldsForAsset, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFieldsForAsset {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFieldsForAsset();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFieldsForAsset>): ListFieldsForAsset {
    return ListFieldsForAsset.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListFieldsForAsset>): ListFieldsForAsset {
    const message = createBaseListFieldsForAsset();
    return message;
  },
};

function createBaseListFieldsForAsset_Request(): ListFieldsForAsset_Request {
  return {
    organization: undefined,
    assetSelection: undefined,
    fieldSelection: undefined,
    pagination: undefined,
    sort: ListFields_Sort.PARSER_PRIORITY,
    applyUnitPreferences: undefined,
    includeTotal: undefined,
    includeHistoricFields: undefined,
  };
}

export const ListFieldsForAsset_Request = {
  encode(message: ListFieldsForAsset_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.assetSelection !== undefined) {
      AssetSelection.encode(message.assetSelection, writer.uint32(18).fork()).ldelim();
    }
    if (message.fieldSelection !== undefined) {
      FieldSelection.encode(message.fieldSelection, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    if (message.sort !== ListFields_Sort.PARSER_PRIORITY) {
      writer.uint32(40).int32(listFields_SortToNumber(message.sort));
    }
    if (message.applyUnitPreferences !== undefined) {
      writer.uint32(48).bool(message.applyUnitPreferences);
    }
    if (message.includeTotal !== undefined) {
      writer.uint32(56).bool(message.includeTotal);
    }
    if (message.includeHistoricFields !== undefined) {
      writer.uint32(64).bool(message.includeHistoricFields);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFieldsForAsset_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFieldsForAsset_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.assetSelection = AssetSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fieldSelection = FieldSelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.sort = listFields_SortFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.applyUnitPreferences = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.includeTotal = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.includeHistoricFields = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFieldsForAsset_Request>): ListFieldsForAsset_Request {
    return ListFieldsForAsset_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListFieldsForAsset_Request>): ListFieldsForAsset_Request {
    const message = createBaseListFieldsForAsset_Request();
    message.organization = object.organization ?? undefined;
    message.assetSelection = (object.assetSelection !== undefined && object.assetSelection !== null)
      ? AssetSelection.fromPartial(object.assetSelection)
      : undefined;
    message.fieldSelection = (object.fieldSelection !== undefined && object.fieldSelection !== null)
      ? FieldSelection.fromPartial(object.fieldSelection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sort = object.sort ?? ListFields_Sort.PARSER_PRIORITY;
    message.applyUnitPreferences = object.applyUnitPreferences ?? undefined;
    message.includeTotal = object.includeTotal ?? undefined;
    message.includeHistoricFields = object.includeHistoricFields ?? undefined;
    return message;
  },
};

function createBaseListFieldsForAsset_Response(): ListFieldsForAsset_Response {
  return { assetFields: [], request: undefined, pagination: undefined, total: [] };
}

export const ListFieldsForAsset_Response = {
  encode(message: ListFieldsForAsset_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.assetFields) {
      ListFieldsForAsset_Response_AssetWithFields.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListFieldsForAsset_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.total) {
      Field.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFieldsForAsset_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFieldsForAsset_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assetFields.push(ListFieldsForAsset_Response_AssetWithFields.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListFieldsForAsset_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.total.push(Field.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFieldsForAsset_Response>): ListFieldsForAsset_Response {
    return ListFieldsForAsset_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListFieldsForAsset_Response>): ListFieldsForAsset_Response {
    const message = createBaseListFieldsForAsset_Response();
    message.assetFields = object.assetFields?.map((e) => ListFieldsForAsset_Response_AssetWithFields.fromPartial(e)) ||
      [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListFieldsForAsset_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.total = object.total?.map((e) => Field.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListFieldsForAsset_Response_AssetWithFields(): ListFieldsForAsset_Response_AssetWithFields {
  return { assetName: "", assetIdentifier: "", fields: [] };
}

export const ListFieldsForAsset_Response_AssetWithFields = {
  encode(message: ListFieldsForAsset_Response_AssetWithFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.assetName !== "") {
      writer.uint32(26).string(message.assetName);
    }
    if (message.assetIdentifier !== "") {
      writer.uint32(10).string(message.assetIdentifier);
    }
    for (const v of message.fields) {
      Field.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFieldsForAsset_Response_AssetWithFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFieldsForAsset_Response_AssetWithFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.assetName = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.assetIdentifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fields.push(Field.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFieldsForAsset_Response_AssetWithFields>): ListFieldsForAsset_Response_AssetWithFields {
    return ListFieldsForAsset_Response_AssetWithFields.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListFieldsForAsset_Response_AssetWithFields>,
  ): ListFieldsForAsset_Response_AssetWithFields {
    const message = createBaseListFieldsForAsset_Response_AssetWithFields();
    message.assetName = object.assetName ?? "";
    message.assetIdentifier = object.assetIdentifier ?? "";
    message.fields = object.fields?.map((e) => Field.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListFieldsForModem(): ListFieldsForModem {
  return {};
}

export const ListFieldsForModem = {
  encode(_: ListFieldsForModem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFieldsForModem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFieldsForModem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFieldsForModem>): ListFieldsForModem {
    return ListFieldsForModem.fromPartial(base ?? {});
  },
  fromPartial(_: DeepPartial<ListFieldsForModem>): ListFieldsForModem {
    const message = createBaseListFieldsForModem();
    return message;
  },
};

function createBaseListFieldsForModem_Request(): ListFieldsForModem_Request {
  return {
    organization: undefined,
    modemSelection: undefined,
    fieldSelection: undefined,
    pagination: undefined,
    sort: ListFields_Sort.PARSER_PRIORITY,
    applyUnitPreferences: undefined,
    includeTotal: undefined,
  };
}

export const ListFieldsForModem_Request = {
  encode(message: ListFieldsForModem_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.modemSelection !== undefined) {
      ModemSelection.encode(message.modemSelection, writer.uint32(18).fork()).ldelim();
    }
    if (message.fieldSelection !== undefined) {
      FieldSelection.encode(message.fieldSelection, writer.uint32(26).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination.encode(message.pagination, writer.uint32(34).fork()).ldelim();
    }
    if (message.sort !== ListFields_Sort.PARSER_PRIORITY) {
      writer.uint32(40).int32(listFields_SortToNumber(message.sort));
    }
    if (message.applyUnitPreferences !== undefined) {
      writer.uint32(48).bool(message.applyUnitPreferences);
    }
    if (message.includeTotal !== undefined) {
      writer.uint32(56).bool(message.includeTotal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFieldsForModem_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFieldsForModem_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modemSelection = ModemSelection.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fieldSelection = FieldSelection.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pagination = Pagination.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.sort = listFields_SortFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.applyUnitPreferences = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.includeTotal = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFieldsForModem_Request>): ListFieldsForModem_Request {
    return ListFieldsForModem_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListFieldsForModem_Request>): ListFieldsForModem_Request {
    const message = createBaseListFieldsForModem_Request();
    message.organization = object.organization ?? undefined;
    message.modemSelection = (object.modemSelection !== undefined && object.modemSelection !== null)
      ? ModemSelection.fromPartial(object.modemSelection)
      : undefined;
    message.fieldSelection = (object.fieldSelection !== undefined && object.fieldSelection !== null)
      ? FieldSelection.fromPartial(object.fieldSelection)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination.fromPartial(object.pagination)
      : undefined;
    message.sort = object.sort ?? ListFields_Sort.PARSER_PRIORITY;
    message.applyUnitPreferences = object.applyUnitPreferences ?? undefined;
    message.includeTotal = object.includeTotal ?? undefined;
    return message;
  },
};

function createBaseListFieldsForModem_Response(): ListFieldsForModem_Response {
  return { modemFields: [], request: undefined, pagination: undefined, total: [] };
}

export const ListFieldsForModem_Response = {
  encode(message: ListFieldsForModem_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modemFields) {
      ListFieldsForModem_Response_ModemWithFields.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.request !== undefined) {
      ListFieldsForModem_Request.encode(message.request, writer.uint32(18).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      Pagination_Result.encode(message.pagination, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.total) {
      Field.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFieldsForModem_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFieldsForModem_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modemFields.push(ListFieldsForModem_Response_ModemWithFields.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.request = ListFieldsForModem_Request.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pagination = Pagination_Result.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.total.push(Field.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFieldsForModem_Response>): ListFieldsForModem_Response {
    return ListFieldsForModem_Response.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ListFieldsForModem_Response>): ListFieldsForModem_Response {
    const message = createBaseListFieldsForModem_Response();
    message.modemFields = object.modemFields?.map((e) => ListFieldsForModem_Response_ModemWithFields.fromPartial(e)) ||
      [];
    message.request = (object.request !== undefined && object.request !== null)
      ? ListFieldsForModem_Request.fromPartial(object.request)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? Pagination_Result.fromPartial(object.pagination)
      : undefined;
    message.total = object.total?.map((e) => Field.fromPartial(e)) || [];
    return message;
  },
};

function createBaseListFieldsForModem_Response_ModemWithFields(): ListFieldsForModem_Response_ModemWithFields {
  return { modem: "", name: "", identifier: "", fields: [] };
}

export const ListFieldsForModem_Response_ModemWithFields = {
  encode(message: ListFieldsForModem_Response_ModemWithFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modem !== "") {
      writer.uint32(10).string(message.modem);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.identifier !== "") {
      writer.uint32(34).string(message.identifier);
    }
    for (const v of message.fields) {
      Field.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListFieldsForModem_Response_ModemWithFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListFieldsForModem_Response_ModemWithFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modem = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fields.push(Field.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ListFieldsForModem_Response_ModemWithFields>): ListFieldsForModem_Response_ModemWithFields {
    return ListFieldsForModem_Response_ModemWithFields.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ListFieldsForModem_Response_ModemWithFields>,
  ): ListFieldsForModem_Response_ModemWithFields {
    const message = createBaseListFieldsForModem_Response_ModemWithFields();
    message.modem = object.modem ?? "";
    message.name = object.name ?? "";
    message.identifier = object.identifier ?? "";
    message.fields = object.fields?.map((e) => Field.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddFieldsRequest(): AddFieldsRequest {
  return { organization: undefined, parserIdentifier: "", fields: [] };
}

export const AddFieldsRequest = {
  encode(message: AddFieldsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.parserIdentifier !== "") {
      writer.uint32(18).string(message.parserIdentifier);
    }
    for (const v of message.fields) {
      Field.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddFieldsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddFieldsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parserIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fields.push(Field.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<AddFieldsRequest>): AddFieldsRequest {
    return AddFieldsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AddFieldsRequest>): AddFieldsRequest {
    const message = createBaseAddFieldsRequest();
    message.organization = object.organization ?? undefined;
    message.parserIdentifier = object.parserIdentifier ?? "";
    message.fields = object.fields?.map((e) => Field.fromPartial(e)) || [];
    return message;
  },
};

function createBaseReplaceAllFieldsRequest(): ReplaceAllFieldsRequest {
  return { organization: undefined, parserIdentifier: "", replaceWithFields: [] };
}

export const ReplaceAllFieldsRequest = {
  encode(message: ReplaceAllFieldsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.parserIdentifier !== "") {
      writer.uint32(18).string(message.parserIdentifier);
    }
    for (const v of message.replaceWithFields) {
      Field.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReplaceAllFieldsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReplaceAllFieldsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parserIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.replaceWithFields.push(Field.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ReplaceAllFieldsRequest>): ReplaceAllFieldsRequest {
    return ReplaceAllFieldsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ReplaceAllFieldsRequest>): ReplaceAllFieldsRequest {
    const message = createBaseReplaceAllFieldsRequest();
    message.organization = object.organization ?? undefined;
    message.parserIdentifier = object.parserIdentifier ?? "";
    message.replaceWithFields = object.replaceWithFields?.map((e) => Field.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteFieldsRequest(): DeleteFieldsRequest {
  return { organization: undefined, parserIdentifier: "", fieldIdentifiers: [] };
}

export const DeleteFieldsRequest = {
  encode(message: DeleteFieldsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.parserIdentifier !== "") {
      writer.uint32(18).string(message.parserIdentifier);
    }
    for (const v of message.fieldIdentifiers) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteFieldsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteFieldsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.parserIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fieldIdentifiers.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<DeleteFieldsRequest>): DeleteFieldsRequest {
    return DeleteFieldsRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeleteFieldsRequest>): DeleteFieldsRequest {
    const message = createBaseDeleteFieldsRequest();
    message.organization = object.organization ?? undefined;
    message.parserIdentifier = object.parserIdentifier ?? "";
    message.fieldIdentifiers = object.fieldIdentifiers?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateFieldRequest(): UpdateFieldRequest {
  return {
    organization: undefined,
    fieldIdentifier: "",
    displayName: undefined,
    deprecatedDisplayName: undefined,
    priority: undefined,
    deprecatedPriority: undefined,
    encrypted: undefined,
    deprecatedEncrypted: undefined,
    optional: undefined,
    deprecatedOptional: undefined,
    operational: undefined,
    updateDetails: undefined,
  };
}

export const UpdateFieldRequest = {
  encode(message: UpdateFieldRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.fieldIdentifier !== "") {
      writer.uint32(18).string(message.fieldIdentifier);
    }
    if (message.displayName !== undefined) {
      writer.uint32(82).string(message.displayName);
    }
    if (message.deprecatedDisplayName !== undefined) {
      UpdateClearableString.encode(message.deprecatedDisplayName, writer.uint32(26).fork()).ldelim();
    }
    if (message.priority !== undefined) {
      writer.uint32(88).uint32(message.priority);
    }
    if (message.deprecatedPriority !== undefined) {
      UpdateZeroableInt.encode(message.deprecatedPriority, writer.uint32(34).fork()).ldelim();
    }
    if (message.encrypted !== undefined) {
      writer.uint32(96).bool(message.encrypted);
    }
    if (message.deprecatedEncrypted !== undefined) {
      UpdateBoolean.encode(message.deprecatedEncrypted, writer.uint32(42).fork()).ldelim();
    }
    if (message.optional !== undefined) {
      writer.uint32(104).bool(message.optional);
    }
    if (message.deprecatedOptional !== undefined) {
      UpdateBoolean.encode(message.deprecatedOptional, writer.uint32(66).fork()).ldelim();
    }
    if (message.operational !== undefined) {
      writer.uint32(72).bool(message.operational);
    }
    switch (message.updateDetails?.$case) {
      case "numeric":
        UpdateFieldNumericDetails.encode(message.updateDetails.numeric, writer.uint32(50).fork()).ldelim();
        break;
      case "enum":
        UpdateFieldEnumValues.encode(message.updateDetails.enum, writer.uint32(58).fork()).ldelim();
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFieldRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFieldRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldIdentifier = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deprecatedDisplayName = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.priority = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deprecatedPriority = UpdateZeroableInt.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.encrypted = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deprecatedEncrypted = UpdateBoolean.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.optional = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.deprecatedOptional = UpdateBoolean.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.operational = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updateDetails = {
            $case: "numeric",
            numeric: UpdateFieldNumericDetails.decode(reader, reader.uint32()),
          };
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.updateDetails = { $case: "enum", enum: UpdateFieldEnumValues.decode(reader, reader.uint32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateFieldRequest>): UpdateFieldRequest {
    return UpdateFieldRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateFieldRequest>): UpdateFieldRequest {
    const message = createBaseUpdateFieldRequest();
    message.organization = object.organization ?? undefined;
    message.fieldIdentifier = object.fieldIdentifier ?? "";
    message.displayName = object.displayName ?? undefined;
    message.deprecatedDisplayName =
      (object.deprecatedDisplayName !== undefined && object.deprecatedDisplayName !== null)
        ? UpdateClearableString.fromPartial(object.deprecatedDisplayName)
        : undefined;
    message.priority = object.priority ?? undefined;
    message.deprecatedPriority = (object.deprecatedPriority !== undefined && object.deprecatedPriority !== null)
      ? UpdateZeroableInt.fromPartial(object.deprecatedPriority)
      : undefined;
    message.encrypted = object.encrypted ?? undefined;
    message.deprecatedEncrypted = (object.deprecatedEncrypted !== undefined && object.deprecatedEncrypted !== null)
      ? UpdateBoolean.fromPartial(object.deprecatedEncrypted)
      : undefined;
    message.optional = object.optional ?? undefined;
    message.deprecatedOptional = (object.deprecatedOptional !== undefined && object.deprecatedOptional !== null)
      ? UpdateBoolean.fromPartial(object.deprecatedOptional)
      : undefined;
    message.operational = object.operational ?? undefined;
    if (
      object.updateDetails?.$case === "numeric" &&
      object.updateDetails?.numeric !== undefined &&
      object.updateDetails?.numeric !== null
    ) {
      message.updateDetails = {
        $case: "numeric",
        numeric: UpdateFieldNumericDetails.fromPartial(object.updateDetails.numeric),
      };
    }
    if (
      object.updateDetails?.$case === "enum" &&
      object.updateDetails?.enum !== undefined &&
      object.updateDetails?.enum !== null
    ) {
      message.updateDetails = { $case: "enum", enum: UpdateFieldEnumValues.fromPartial(object.updateDetails.enum) };
    }
    return message;
  },
};

function createBaseUpdateFieldNumericDetails(): UpdateFieldNumericDetails {
  return { replaceUnit: undefined, replaceFormat: undefined, replaceUnitOfMeasurement: undefined };
}

export const UpdateFieldNumericDetails = {
  encode(message: UpdateFieldNumericDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.replaceUnit !== undefined) {
      Field_Numeric_Unit.encode(message.replaceUnit, writer.uint32(34).fork()).ldelim();
    }
    if (message.replaceFormat !== undefined) {
      Field_Numeric_Format.encode(message.replaceFormat, writer.uint32(42).fork()).ldelim();
    }
    if (message.replaceUnitOfMeasurement !== undefined) {
      writer.uint32(48).int32(unitOfMeasurementToNumber(message.replaceUnitOfMeasurement));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFieldNumericDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFieldNumericDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.replaceUnit = Field_Numeric_Unit.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.replaceFormat = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.replaceUnitOfMeasurement = unitOfMeasurementFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateFieldNumericDetails>): UpdateFieldNumericDetails {
    return UpdateFieldNumericDetails.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateFieldNumericDetails>): UpdateFieldNumericDetails {
    const message = createBaseUpdateFieldNumericDetails();
    message.replaceUnit = (object.replaceUnit !== undefined && object.replaceUnit !== null)
      ? Field_Numeric_Unit.fromPartial(object.replaceUnit)
      : undefined;
    message.replaceFormat = (object.replaceFormat !== undefined && object.replaceFormat !== null)
      ? Field_Numeric_Format.fromPartial(object.replaceFormat)
      : undefined;
    message.replaceUnitOfMeasurement = object.replaceUnitOfMeasurement ?? undefined;
    return message;
  },
};

function createBaseUpdateFieldNumericDetails_Request(): UpdateFieldNumericDetails_Request {
  return { organization: undefined, fieldIdentifier: "", update: undefined };
}

export const UpdateFieldNumericDetails_Request = {
  encode(message: UpdateFieldNumericDetails_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.fieldIdentifier !== "") {
      writer.uint32(18).string(message.fieldIdentifier);
    }
    if (message.update !== undefined) {
      UpdateFieldNumericDetails.encode(message.update, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFieldNumericDetails_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFieldNumericDetails_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = UpdateFieldNumericDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateFieldNumericDetails_Request>): UpdateFieldNumericDetails_Request {
    return UpdateFieldNumericDetails_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateFieldNumericDetails_Request>): UpdateFieldNumericDetails_Request {
    const message = createBaseUpdateFieldNumericDetails_Request();
    message.organization = object.organization ?? undefined;
    message.fieldIdentifier = object.fieldIdentifier ?? "";
    message.update = (object.update !== undefined && object.update !== null)
      ? UpdateFieldNumericDetails.fromPartial(object.update)
      : undefined;
    return message;
  },
};

function createBaseUpdateFieldEnumValues(): UpdateFieldEnumValues {
  return { addValues: [], removeValues: [], updateValues: [], replaceAllValues: [] };
}

export const UpdateFieldEnumValues = {
  encode(message: UpdateFieldEnumValues, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.addValues) {
      Value_Enum.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.removeValues) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.updateValues) {
      UpdateFieldEnumValues_UpdateEnumValue.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.replaceAllValues) {
      Value_Enum.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFieldEnumValues {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFieldEnumValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.addValues.push(Value_Enum.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.removeValues.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updateValues.push(UpdateFieldEnumValues_UpdateEnumValue.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.replaceAllValues.push(Value_Enum.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateFieldEnumValues>): UpdateFieldEnumValues {
    return UpdateFieldEnumValues.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateFieldEnumValues>): UpdateFieldEnumValues {
    const message = createBaseUpdateFieldEnumValues();
    message.addValues = object.addValues?.map((e) => Value_Enum.fromPartial(e)) || [];
    message.removeValues = object.removeValues?.map((e) => e) || [];
    message.updateValues = object.updateValues?.map((e) => UpdateFieldEnumValues_UpdateEnumValue.fromPartial(e)) || [];
    message.replaceAllValues = object.replaceAllValues?.map((e) => Value_Enum.fromPartial(e)) || [];
    return message;
  },
};

function createBaseUpdateFieldEnumValues_Request(): UpdateFieldEnumValues_Request {
  return { organization: undefined, fieldIdentifier: "", update: undefined };
}

export const UpdateFieldEnumValues_Request = {
  encode(message: UpdateFieldEnumValues_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.organization !== undefined) {
      writer.uint32(10).string(message.organization);
    }
    if (message.fieldIdentifier !== "") {
      writer.uint32(18).string(message.fieldIdentifier);
    }
    if (message.update !== undefined) {
      UpdateFieldEnumValues.encode(message.update, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFieldEnumValues_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFieldEnumValues_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organization = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fieldIdentifier = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.update = UpdateFieldEnumValues.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateFieldEnumValues_Request>): UpdateFieldEnumValues_Request {
    return UpdateFieldEnumValues_Request.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateFieldEnumValues_Request>): UpdateFieldEnumValues_Request {
    const message = createBaseUpdateFieldEnumValues_Request();
    message.organization = object.organization ?? undefined;
    message.fieldIdentifier = object.fieldIdentifier ?? "";
    message.update = (object.update !== undefined && object.update !== null)
      ? UpdateFieldEnumValues.fromPartial(object.update)
      : undefined;
    return message;
  },
};

function createBaseUpdateFieldEnumValues_UpdateEnumValue(): UpdateFieldEnumValues_UpdateEnumValue {
  return {
    value: "",
    displayName: undefined,
    updateDisplayName: undefined,
    description: undefined,
    updateDescription: undefined,
    color: undefined,
    updateColor: undefined,
    priority: undefined,
    updatePriority: undefined,
  };
}

export const UpdateFieldEnumValues_UpdateEnumValue = {
  encode(message: UpdateFieldEnumValues_UpdateEnumValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    if (message.displayName !== undefined) {
      writer.uint32(50).string(message.displayName);
    }
    if (message.updateDisplayName !== undefined) {
      UpdateClearableString.encode(message.updateDisplayName, writer.uint32(18).fork()).ldelim();
    }
    if (message.description !== undefined) {
      writer.uint32(58).string(message.description);
    }
    if (message.updateDescription !== undefined) {
      UpdateClearableString.encode(message.updateDescription, writer.uint32(26).fork()).ldelim();
    }
    if (message.color !== undefined) {
      writer.uint32(66).string(message.color);
    }
    if (message.updateColor !== undefined) {
      UpdateClearableString.encode(message.updateColor, writer.uint32(34).fork()).ldelim();
    }
    if (message.priority !== undefined) {
      writer.uint32(72).uint32(message.priority);
    }
    if (message.updatePriority !== undefined) {
      UpdateZeroableInt.encode(message.updatePriority, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateFieldEnumValues_UpdateEnumValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateFieldEnumValues_UpdateEnumValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updateDisplayName = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updateDescription = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.color = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updateColor = UpdateClearableString.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.priority = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.updatePriority = UpdateZeroableInt.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<UpdateFieldEnumValues_UpdateEnumValue>): UpdateFieldEnumValues_UpdateEnumValue {
    return UpdateFieldEnumValues_UpdateEnumValue.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<UpdateFieldEnumValues_UpdateEnumValue>): UpdateFieldEnumValues_UpdateEnumValue {
    const message = createBaseUpdateFieldEnumValues_UpdateEnumValue();
    message.value = object.value ?? "";
    message.displayName = object.displayName ?? undefined;
    message.updateDisplayName = (object.updateDisplayName !== undefined && object.updateDisplayName !== null)
      ? UpdateClearableString.fromPartial(object.updateDisplayName)
      : undefined;
    message.description = object.description ?? undefined;
    message.updateDescription = (object.updateDescription !== undefined && object.updateDescription !== null)
      ? UpdateClearableString.fromPartial(object.updateDescription)
      : undefined;
    message.color = object.color ?? undefined;
    message.updateColor = (object.updateColor !== undefined && object.updateColor !== null)
      ? UpdateClearableString.fromPartial(object.updateColor)
      : undefined;
    message.priority = object.priority ?? undefined;
    message.updatePriority = (object.updatePriority !== undefined && object.updatePriority !== null)
      ? UpdateZeroableInt.fromPartial(object.updatePriority)
      : undefined;
    return message;
  },
};

export type FieldServiceDefinition = typeof FieldServiceDefinition;
export const FieldServiceDefinition = {
  name: "FieldService",
  fullName: "hiber.field.FieldService",
  methods: {
    list: {
      name: "List",
      requestType: ListFields_Request,
      requestStream: false,
      responseType: ListFields_Response,
      responseStream: false,
      options: {},
    },
    forAsset: {
      name: "ForAsset",
      requestType: ListFieldsForAsset_Request,
      requestStream: false,
      responseType: ListFieldsForAsset_Response,
      responseStream: false,
      options: {},
    },
    forModem: {
      name: "ForModem",
      requestType: ListFieldsForModem_Request,
      requestStream: false,
      responseType: ListFieldsForModem_Response,
      responseStream: false,
      options: {},
    },
    add: {
      name: "Add",
      requestType: AddFieldsRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    replaceAll: {
      name: "ReplaceAll",
      requestType: ReplaceAllFieldsRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    delete: {
      name: "Delete",
      requestType: DeleteFieldsRequest,
      requestStream: false,
      responseType: ModemMessageBodyParser,
      responseStream: false,
      options: {},
    },
    update: {
      name: "Update",
      requestType: UpdateFieldRequest,
      requestStream: false,
      responseType: Field,
      responseStream: false,
      options: {},
    },
    updateNumericDetails: {
      name: "UpdateNumericDetails",
      requestType: UpdateFieldNumericDetails_Request,
      requestStream: false,
      responseType: Field,
      responseStream: false,
      options: {},
    },
    updateEnumValues: {
      name: "UpdateEnumValues",
      requestType: UpdateFieldEnumValues_Request,
      requestStream: false,
      responseType: Field,
      responseStream: false,
      options: {},
    },
  },
} as const;

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
