// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.2
//   protoc               v3.19.1
// source: field.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { UnitOfMeasurement, unitOfMeasurementFromJSON, unitOfMeasurementToNumber } from "./base";
import {
  Value_Enum,
  Value_Numeric_BatteryLevel_BatteryLevelUnit,
  value_Numeric_BatteryLevel_BatteryLevelUnitFromJSON,
  value_Numeric_BatteryLevel_BatteryLevelUnitToNumber,
  Value_Numeric_Distance_DistanceUnit,
  value_Numeric_Distance_DistanceUnitFromJSON,
  value_Numeric_Distance_DistanceUnitToNumber,
  Value_Numeric_Duration_DurationUnit,
  value_Numeric_Duration_DurationUnitFromJSON,
  value_Numeric_Duration_DurationUnitToNumber,
  Value_Numeric_Flow_FlowUnit,
  value_Numeric_Flow_FlowUnitFromJSON,
  value_Numeric_Flow_FlowUnitToNumber,
  Value_Numeric_FuelEfficiency_FuelEfficiencyUnit,
  value_Numeric_FuelEfficiency_FuelEfficiencyUnitFromJSON,
  value_Numeric_FuelEfficiency_FuelEfficiencyUnitToNumber,
  Value_Numeric_Mass_MassUnit,
  value_Numeric_Mass_MassUnitFromJSON,
  value_Numeric_Mass_MassUnitToNumber,
  Value_Numeric_Percentage_PercentageUnit,
  value_Numeric_Percentage_PercentageUnitFromJSON,
  value_Numeric_Percentage_PercentageUnitToNumber,
  Value_Numeric_Pressure_PressureUnit,
  value_Numeric_Pressure_PressureUnitFromJSON,
  value_Numeric_Pressure_PressureUnitToNumber,
  Value_Numeric_Rate_RateUnit,
  value_Numeric_Rate_RateUnitFromJSON,
  value_Numeric_Rate_RateUnitToNumber,
  Value_Numeric_RotationSpeed_RotationSpeedUnit,
  value_Numeric_RotationSpeed_RotationSpeedUnitFromJSON,
  value_Numeric_RotationSpeed_RotationSpeedUnitToNumber,
  Value_Numeric_Speed_SpeedUnit,
  value_Numeric_Speed_SpeedUnitFromJSON,
  value_Numeric_Speed_SpeedUnitToNumber,
  Value_Numeric_Temperature_TemperatureUnit,
  value_Numeric_Temperature_TemperatureUnitFromJSON,
  value_Numeric_Temperature_TemperatureUnitToNumber,
  Value_Numeric_Type,
  value_Numeric_TypeFromJSON,
  value_Numeric_TypeToNumber,
  Value_Numeric_Voltage_VoltageUnit,
  value_Numeric_Voltage_VoltageUnitFromJSON,
  value_Numeric_Voltage_VoltageUnitToNumber,
  Value_Numeric_Volume_VolumeUnit,
  value_Numeric_Volume_VolumeUnitFromJSON,
  value_Numeric_Volume_VolumeUnitToNumber,
  Value_Type,
  value_TypeFromJSON,
  value_TypeToNumber,
} from "./value";

export const protobufPackage = "hiber.field";

export interface Field {
  /** Unique identifier for this field. */
  identifier: string;
  /** Unique identifier for the parser this field belongs to. */
  parserIdentifier: string;
  /** Name of the parser this field belongs to. */
  parserName: string;
  /** The name of the field (if in the root structure) or a JsonPath to the field. */
  field: string;
  /** An optional display name for the field. */
  displayName?:
    | string
    | undefined;
  /** Priority of the field, typically used for ordering. */
  priority: number;
  /** The type of value the field contains. */
  type: Value_Type;
  details?:
    | { $case: "numeric"; numeric: Field_Numeric }
    | { $case: "enum"; enum: Field_Enum }
    | undefined;
  /**
   * Whether this field should be stored encrypted or not.
   * If it is, some processing options may be unavailable or slower.
   */
  encrypted: boolean;
  /** Whether this field should be validated from the parser output. */
  optional: boolean;
  /** Field contains values relevant for operating the device. */
  operational: boolean;
  /**
   * If numeric, the unit of the field.
   * Deprecated: use numeric.numeric_unit oneof instead
   *
   * @deprecated
   */
  unitOfMeasurement?:
    | UnitOfMeasurement
    | undefined;
  /**
   * The symbol for the unit.
   * Deprecated: use numeric.symbol instead
   *
   * @deprecated
   */
  unitSymbol?: string | undefined;
}

/** If the field is numeric, this specifies the unit and formatting details for the field. */
export interface Field_Numeric {
  /** The type of numeric value. */
  type: Value_Numeric_Type;
  /** The symbol to use for the field's unit. */
  symbol: string;
  /** How to format the values (number of decimals, rounding, etc.). */
  format:
    | Field_Numeric_Format
    | undefined;
  /** The unit for the field, depending on the type. */
  unit:
    | Field_Numeric_Unit
    | undefined;
  /**
   * If the unit preferences were applied, and the unit is different, the field will be converted to the preferred
   * unit, from the original unit specified in this field.
   */
  convertedFrom?: Field_Numeric_Unit | undefined;
}

export interface Field_Numeric_Unit {
  unit?:
    | { $case: "batteryLevel"; batteryLevel: Value_Numeric_BatteryLevel_BatteryLevelUnit }
    | { $case: "distance"; distance: Value_Numeric_Distance_DistanceUnit }
    | { $case: "duration"; duration: Value_Numeric_Duration_DurationUnit }
    | { $case: "fuelEfficiency"; fuelEfficiency: Value_Numeric_FuelEfficiency_FuelEfficiencyUnit }
    | { $case: "flow"; flow: Value_Numeric_Flow_FlowUnit }
    | { $case: "percentage"; percentage: Value_Numeric_Percentage_PercentageUnit }
    | { $case: "pressure"; pressure: Value_Numeric_Pressure_PressureUnit }
    | { $case: "speed"; speed: Value_Numeric_Speed_SpeedUnit }
    | { $case: "temperature"; temperature: Value_Numeric_Temperature_TemperatureUnit }
    | { $case: "voltage"; voltage: Value_Numeric_Voltage_VoltageUnit }
    | { $case: "volume"; volume: Value_Numeric_Volume_VolumeUnit }
    | { $case: "mass"; mass: Value_Numeric_Mass_MassUnit }
    | { $case: "rotationSpeed"; rotationSpeed: Value_Numeric_RotationSpeed_RotationSpeedUnit }
    | { $case: "rate"; rate: Value_Numeric_Rate_RateUnit }
    | undefined;
}

/** Formatting options for the field. */
export interface Field_Numeric_Format {
  round?:
    | { $case: "roundToInteger"; roundToInteger: boolean }
    | { $case: "roundToScale"; roundToScale: number }
    | undefined;
  /** How to round the value when scale is applied. */
  roundingMode: Field_Numeric_Format_RoundingMode;
}

/**
 * How to round the value when scale is applied.
 * For example, a value of 3.1415 with scale 3 could be
 *   4.141 (DOWN, HALF_DOWN, FLOOR) or
 *   4.142 (HALF_UP, UP, CEILING).
 */
export enum Field_Numeric_Format_RoundingMode {
  /**
   * HALF_UP - Round towards nearest neighbor unless both neighbors are equidistant, in which case round up
   * Effectively round up when >= .5, otherwise round down.
   */
  HALF_UP = "HALF_UP",
  /**
   * HALF_DOWN - Round towards nearest neighbor unless both neighbors are equidistant, in which case round down.
   * Effectively round up when > .5, otherwise round down.
   */
  HALF_DOWN = "HALF_DOWN",
  /** UP - Round away from zero: 1.1 -> 2, while -1.1 -> -2. */
  UP = "UP",
  /** DOWN - Round towards zero: 1.1 -> 1, while -1.1 -> -1. */
  DOWN = "DOWN",
  /** CEILING - Round towards positive infinity. */
  CEILING = "CEILING",
  /** FLOOR - Round towards negative infinity. */
  FLOOR = "FLOOR",
  /**
   * HALF_EVEN - Round towards the nearest neighbor unless both neighbors are equidistant, in which case,
   * round towards the even neighbor.
   * Effectively round up when >= .5 and next integer value is even, otherwise round down.
   */
  HALF_EVEN = "HALF_EVEN",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function field_Numeric_Format_RoundingModeFromJSON(object: any): Field_Numeric_Format_RoundingMode {
  switch (object) {
    case 0:
    case "HALF_UP":
      return Field_Numeric_Format_RoundingMode.HALF_UP;
    case 1:
    case "HALF_DOWN":
      return Field_Numeric_Format_RoundingMode.HALF_DOWN;
    case 3:
    case "UP":
      return Field_Numeric_Format_RoundingMode.UP;
    case 4:
    case "DOWN":
      return Field_Numeric_Format_RoundingMode.DOWN;
    case 5:
    case "CEILING":
      return Field_Numeric_Format_RoundingMode.CEILING;
    case 6:
    case "FLOOR":
      return Field_Numeric_Format_RoundingMode.FLOOR;
    case 7:
    case "HALF_EVEN":
      return Field_Numeric_Format_RoundingMode.HALF_EVEN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return Field_Numeric_Format_RoundingMode.UNRECOGNIZED;
  }
}

export function field_Numeric_Format_RoundingModeToNumber(object: Field_Numeric_Format_RoundingMode): number {
  switch (object) {
    case Field_Numeric_Format_RoundingMode.HALF_UP:
      return 0;
    case Field_Numeric_Format_RoundingMode.HALF_DOWN:
      return 1;
    case Field_Numeric_Format_RoundingMode.UP:
      return 3;
    case Field_Numeric_Format_RoundingMode.DOWN:
      return 4;
    case Field_Numeric_Format_RoundingMode.CEILING:
      return 5;
    case Field_Numeric_Format_RoundingMode.FLOOR:
      return 6;
    case Field_Numeric_Format_RoundingMode.HALF_EVEN:
      return 7;
    case Field_Numeric_Format_RoundingMode.UNRECOGNIZED:
    default:
      return -1;
  }
}

/** If the field is an enum, this specifies the enum values for the field. */
export interface Field_Enum {
  values: Value_Enum[];
}

function createBaseField(): Field {
  return {
    identifier: "",
    parserIdentifier: "",
    parserName: "",
    field: "",
    displayName: undefined,
    priority: 0,
    type: Value_Type.OTHER,
    details: undefined,
    encrypted: false,
    optional: false,
    operational: false,
    unitOfMeasurement: undefined,
    unitSymbol: undefined,
  };
}

export const Field = {
  encode(message: Field, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identifier !== "") {
      writer.uint32(90).string(message.identifier);
    }
    if (message.parserIdentifier !== "") {
      writer.uint32(114).string(message.parserIdentifier);
    }
    if (message.parserName !== "") {
      writer.uint32(122).string(message.parserName);
    }
    if (message.field !== "") {
      writer.uint32(10).string(message.field);
    }
    if (message.displayName !== undefined) {
      writer.uint32(18).string(message.displayName);
    }
    if (message.priority !== 0) {
      writer.uint32(56).int32(message.priority);
    }
    if (message.type !== Value_Type.OTHER) {
      writer.uint32(40).int32(value_TypeToNumber(message.type));
    }
    switch (message.details?.$case) {
      case "numeric":
        Field_Numeric.encode(message.details.numeric, writer.uint32(74).fork()).ldelim();
        break;
      case "enum":
        Field_Enum.encode(message.details.enum, writer.uint32(82).fork()).ldelim();
        break;
    }
    if (message.encrypted !== false) {
      writer.uint32(24).bool(message.encrypted);
    }
    if (message.optional !== false) {
      writer.uint32(96).bool(message.optional);
    }
    if (message.operational !== false) {
      writer.uint32(104).bool(message.operational);
    }
    if (message.unitOfMeasurement !== undefined) {
      writer.uint32(32).int32(unitOfMeasurementToNumber(message.unitOfMeasurement));
    }
    if (message.unitSymbol !== undefined) {
      writer.uint32(50).string(message.unitSymbol);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Field {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseField();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 11:
          if (tag !== 90) {
            break;
          }

          message.identifier = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.parserIdentifier = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.parserName = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.field = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.priority = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = value_TypeFromJSON(reader.int32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.details = { $case: "numeric", numeric: Field_Numeric.decode(reader, reader.uint32()) };
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.details = { $case: "enum", enum: Field_Enum.decode(reader, reader.uint32()) };
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.encrypted = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.optional = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.operational = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.unitOfMeasurement = unitOfMeasurementFromJSON(reader.int32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.unitSymbol = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Field>): Field {
    return Field.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Field>): Field {
    const message = createBaseField();
    message.identifier = object.identifier ?? "";
    message.parserIdentifier = object.parserIdentifier ?? "";
    message.parserName = object.parserName ?? "";
    message.field = object.field ?? "";
    message.displayName = object.displayName ?? undefined;
    message.priority = object.priority ?? 0;
    message.type = object.type ?? Value_Type.OTHER;
    if (
      object.details?.$case === "numeric" && object.details?.numeric !== undefined && object.details?.numeric !== null
    ) {
      message.details = { $case: "numeric", numeric: Field_Numeric.fromPartial(object.details.numeric) };
    }
    if (object.details?.$case === "enum" && object.details?.enum !== undefined && object.details?.enum !== null) {
      message.details = { $case: "enum", enum: Field_Enum.fromPartial(object.details.enum) };
    }
    message.encrypted = object.encrypted ?? false;
    message.optional = object.optional ?? false;
    message.operational = object.operational ?? false;
    message.unitOfMeasurement = object.unitOfMeasurement ?? undefined;
    message.unitSymbol = object.unitSymbol ?? undefined;
    return message;
  },
};

function createBaseField_Numeric(): Field_Numeric {
  return {
    type: Value_Numeric_Type.TYPE_UNKNOWN,
    symbol: "",
    format: undefined,
    unit: undefined,
    convertedFrom: undefined,
  };
}

export const Field_Numeric = {
  encode(message: Field_Numeric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== Value_Numeric_Type.TYPE_UNKNOWN) {
      writer.uint32(8).int32(value_Numeric_TypeToNumber(message.type));
    }
    if (message.symbol !== "") {
      writer.uint32(18).string(message.symbol);
    }
    if (message.format !== undefined) {
      Field_Numeric_Format.encode(message.format, writer.uint32(26).fork()).ldelim();
    }
    if (message.unit !== undefined) {
      Field_Numeric_Unit.encode(message.unit, writer.uint32(34).fork()).ldelim();
    }
    if (message.convertedFrom !== undefined) {
      Field_Numeric_Unit.encode(message.convertedFrom, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Field_Numeric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseField_Numeric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.type = value_Numeric_TypeFromJSON(reader.int32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.symbol = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.format = Field_Numeric_Format.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.unit = Field_Numeric_Unit.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.convertedFrom = Field_Numeric_Unit.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Field_Numeric>): Field_Numeric {
    return Field_Numeric.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Field_Numeric>): Field_Numeric {
    const message = createBaseField_Numeric();
    message.type = object.type ?? Value_Numeric_Type.TYPE_UNKNOWN;
    message.symbol = object.symbol ?? "";
    message.format = (object.format !== undefined && object.format !== null)
      ? Field_Numeric_Format.fromPartial(object.format)
      : undefined;
    message.unit = (object.unit !== undefined && object.unit !== null)
      ? Field_Numeric_Unit.fromPartial(object.unit)
      : undefined;
    message.convertedFrom = (object.convertedFrom !== undefined && object.convertedFrom !== null)
      ? Field_Numeric_Unit.fromPartial(object.convertedFrom)
      : undefined;
    return message;
  },
};

function createBaseField_Numeric_Unit(): Field_Numeric_Unit {
  return { unit: undefined };
}

export const Field_Numeric_Unit = {
  encode(message: Field_Numeric_Unit, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.unit?.$case) {
      case "batteryLevel":
        writer.uint32(8).int32(value_Numeric_BatteryLevel_BatteryLevelUnitToNumber(message.unit.batteryLevel));
        break;
      case "distance":
        writer.uint32(16).int32(value_Numeric_Distance_DistanceUnitToNumber(message.unit.distance));
        break;
      case "duration":
        writer.uint32(24).int32(value_Numeric_Duration_DurationUnitToNumber(message.unit.duration));
        break;
      case "fuelEfficiency":
        writer.uint32(32).int32(value_Numeric_FuelEfficiency_FuelEfficiencyUnitToNumber(message.unit.fuelEfficiency));
        break;
      case "flow":
        writer.uint32(40).int32(value_Numeric_Flow_FlowUnitToNumber(message.unit.flow));
        break;
      case "percentage":
        writer.uint32(96).int32(value_Numeric_Percentage_PercentageUnitToNumber(message.unit.percentage));
        break;
      case "pressure":
        writer.uint32(48).int32(value_Numeric_Pressure_PressureUnitToNumber(message.unit.pressure));
        break;
      case "speed":
        writer.uint32(56).int32(value_Numeric_Speed_SpeedUnitToNumber(message.unit.speed));
        break;
      case "temperature":
        writer.uint32(64).int32(value_Numeric_Temperature_TemperatureUnitToNumber(message.unit.temperature));
        break;
      case "voltage":
        writer.uint32(72).int32(value_Numeric_Voltage_VoltageUnitToNumber(message.unit.voltage));
        break;
      case "volume":
        writer.uint32(80).int32(value_Numeric_Volume_VolumeUnitToNumber(message.unit.volume));
        break;
      case "mass":
        writer.uint32(88).int32(value_Numeric_Mass_MassUnitToNumber(message.unit.mass));
        break;
      case "rotationSpeed":
        writer.uint32(104).int32(value_Numeric_RotationSpeed_RotationSpeedUnitToNumber(message.unit.rotationSpeed));
        break;
      case "rate":
        writer.uint32(112).int32(value_Numeric_Rate_RateUnitToNumber(message.unit.rate));
        break;
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Field_Numeric_Unit {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseField_Numeric_Unit();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.unit = {
            $case: "batteryLevel",
            batteryLevel: value_Numeric_BatteryLevel_BatteryLevelUnitFromJSON(reader.int32()),
          };
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = { $case: "distance", distance: value_Numeric_Distance_DistanceUnitFromJSON(reader.int32()) };
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.unit = { $case: "duration", duration: value_Numeric_Duration_DurationUnitFromJSON(reader.int32()) };
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.unit = {
            $case: "fuelEfficiency",
            fuelEfficiency: value_Numeric_FuelEfficiency_FuelEfficiencyUnitFromJSON(reader.int32()),
          };
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.unit = { $case: "flow", flow: value_Numeric_Flow_FlowUnitFromJSON(reader.int32()) };
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.unit = {
            $case: "percentage",
            percentage: value_Numeric_Percentage_PercentageUnitFromJSON(reader.int32()),
          };
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.unit = { $case: "pressure", pressure: value_Numeric_Pressure_PressureUnitFromJSON(reader.int32()) };
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.unit = { $case: "speed", speed: value_Numeric_Speed_SpeedUnitFromJSON(reader.int32()) };
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.unit = {
            $case: "temperature",
            temperature: value_Numeric_Temperature_TemperatureUnitFromJSON(reader.int32()),
          };
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.unit = { $case: "voltage", voltage: value_Numeric_Voltage_VoltageUnitFromJSON(reader.int32()) };
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.unit = { $case: "volume", volume: value_Numeric_Volume_VolumeUnitFromJSON(reader.int32()) };
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.unit = { $case: "mass", mass: value_Numeric_Mass_MassUnitFromJSON(reader.int32()) };
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.unit = {
            $case: "rotationSpeed",
            rotationSpeed: value_Numeric_RotationSpeed_RotationSpeedUnitFromJSON(reader.int32()),
          };
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.unit = { $case: "rate", rate: value_Numeric_Rate_RateUnitFromJSON(reader.int32()) };
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Field_Numeric_Unit>): Field_Numeric_Unit {
    return Field_Numeric_Unit.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Field_Numeric_Unit>): Field_Numeric_Unit {
    const message = createBaseField_Numeric_Unit();
    if (
      object.unit?.$case === "batteryLevel" &&
      object.unit?.batteryLevel !== undefined &&
      object.unit?.batteryLevel !== null
    ) {
      message.unit = { $case: "batteryLevel", batteryLevel: object.unit.batteryLevel };
    }
    if (object.unit?.$case === "distance" && object.unit?.distance !== undefined && object.unit?.distance !== null) {
      message.unit = { $case: "distance", distance: object.unit.distance };
    }
    if (object.unit?.$case === "duration" && object.unit?.duration !== undefined && object.unit?.duration !== null) {
      message.unit = { $case: "duration", duration: object.unit.duration };
    }
    if (
      object.unit?.$case === "fuelEfficiency" &&
      object.unit?.fuelEfficiency !== undefined &&
      object.unit?.fuelEfficiency !== null
    ) {
      message.unit = { $case: "fuelEfficiency", fuelEfficiency: object.unit.fuelEfficiency };
    }
    if (object.unit?.$case === "flow" && object.unit?.flow !== undefined && object.unit?.flow !== null) {
      message.unit = { $case: "flow", flow: object.unit.flow };
    }
    if (
      object.unit?.$case === "percentage" && object.unit?.percentage !== undefined && object.unit?.percentage !== null
    ) {
      message.unit = { $case: "percentage", percentage: object.unit.percentage };
    }
    if (object.unit?.$case === "pressure" && object.unit?.pressure !== undefined && object.unit?.pressure !== null) {
      message.unit = { $case: "pressure", pressure: object.unit.pressure };
    }
    if (object.unit?.$case === "speed" && object.unit?.speed !== undefined && object.unit?.speed !== null) {
      message.unit = { $case: "speed", speed: object.unit.speed };
    }
    if (
      object.unit?.$case === "temperature" &&
      object.unit?.temperature !== undefined &&
      object.unit?.temperature !== null
    ) {
      message.unit = { $case: "temperature", temperature: object.unit.temperature };
    }
    if (object.unit?.$case === "voltage" && object.unit?.voltage !== undefined && object.unit?.voltage !== null) {
      message.unit = { $case: "voltage", voltage: object.unit.voltage };
    }
    if (object.unit?.$case === "volume" && object.unit?.volume !== undefined && object.unit?.volume !== null) {
      message.unit = { $case: "volume", volume: object.unit.volume };
    }
    if (object.unit?.$case === "mass" && object.unit?.mass !== undefined && object.unit?.mass !== null) {
      message.unit = { $case: "mass", mass: object.unit.mass };
    }
    if (
      object.unit?.$case === "rotationSpeed" &&
      object.unit?.rotationSpeed !== undefined &&
      object.unit?.rotationSpeed !== null
    ) {
      message.unit = { $case: "rotationSpeed", rotationSpeed: object.unit.rotationSpeed };
    }
    if (object.unit?.$case === "rate" && object.unit?.rate !== undefined && object.unit?.rate !== null) {
      message.unit = { $case: "rate", rate: object.unit.rate };
    }
    return message;
  },
};

function createBaseField_Numeric_Format(): Field_Numeric_Format {
  return { round: undefined, roundingMode: Field_Numeric_Format_RoundingMode.HALF_UP };
}

export const Field_Numeric_Format = {
  encode(message: Field_Numeric_Format, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    switch (message.round?.$case) {
      case "roundToInteger":
        writer.uint32(24).bool(message.round.roundToInteger);
        break;
      case "roundToScale":
        writer.uint32(8).uint32(message.round.roundToScale);
        break;
    }
    if (message.roundingMode !== Field_Numeric_Format_RoundingMode.HALF_UP) {
      writer.uint32(16).int32(field_Numeric_Format_RoundingModeToNumber(message.roundingMode));
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Field_Numeric_Format {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseField_Numeric_Format();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 24) {
            break;
          }

          message.round = { $case: "roundToInteger", roundToInteger: reader.bool() };
          continue;
        case 1:
          if (tag !== 8) {
            break;
          }

          message.round = { $case: "roundToScale", roundToScale: reader.uint32() };
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.roundingMode = field_Numeric_Format_RoundingModeFromJSON(reader.int32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Field_Numeric_Format>): Field_Numeric_Format {
    return Field_Numeric_Format.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Field_Numeric_Format>): Field_Numeric_Format {
    const message = createBaseField_Numeric_Format();
    if (
      object.round?.$case === "roundToInteger" &&
      object.round?.roundToInteger !== undefined &&
      object.round?.roundToInteger !== null
    ) {
      message.round = { $case: "roundToInteger", roundToInteger: object.round.roundToInteger };
    }
    if (
      object.round?.$case === "roundToScale" &&
      object.round?.roundToScale !== undefined &&
      object.round?.roundToScale !== null
    ) {
      message.round = { $case: "roundToScale", roundToScale: object.round.roundToScale };
    }
    message.roundingMode = object.roundingMode ?? Field_Numeric_Format_RoundingMode.HALF_UP;
    return message;
  },
};

function createBaseField_Enum(): Field_Enum {
  return { values: [] };
}

export const Field_Enum = {
  encode(message: Field_Enum, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.values) {
      Value_Enum.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Field_Enum {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseField_Enum();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.values.push(Value_Enum.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Field_Enum>): Field_Enum {
    return Field_Enum.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Field_Enum>): Field_Enum {
    const message = createBaseField_Enum();
    message.values = object.values?.map((e) => Value_Enum.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
